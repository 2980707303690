.header {
  background-color: var(--white);
  border-bottom: 1px solid var(--grey-6);
  padding: 20px 0;
  width: 100%;
  height: 98px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;

  svg {
    display: block;
    height: 58px;
  }
}

.container {
  display: flex;
  align-items: center;
  justify-content: space-between;

  a {
    text-decoration: none;
    color: var(--text);
  }
}

.phone {
  font-size: 20px;
  font-weight: bold;

  a {
    text-decoration: none;
    color: var(--brand);
  }
}

.decor {
  width: 10px;
  height: 56px;
  background: var(--grey-6);
  border-radius: var(--border-radius);
}

@media screen and (max-width: 960px) {
  .header {
    padding: 20px 5px;
    height: 98px;
  }

  .phone {
    display: none;
  }
}
