.wrapper {
  display: flex;
  align-items: center;
  justify-content: start;
}

.info {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  margin-left: 20px;
}

.title {
  font-size: 20px;
  font-weight: 600;
}

.subtitle {
  font-size: 11px;
  font-weight: bold;
  letter-spacing: 1.3px;
  text-transform: uppercase;
}
