@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;700&display=swap');

$breakpoints: sm, md, lg, xl, xxl;
$widths: 25, 30, 40, 50, 60, 70, 75, 100;

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

img {
  border: none;
  outline: none;
  font-size: 0;
}

body {
  font-family: 'Inter', sans-serif;

  --black: #000;
  --white: #fff;
  --grey: #ccc;

  --grey-1: #333333;
  --grey-2: #4f4f4f;
  --grey-3: #828282;
  --grey-4: #dbdbdb;
  --grey-5: #e0e0e0;
  --grey-6: #f2f2f2;

  --text-1: --black;
  --text-2: --white;
  --text-3: --grey-2;
  --text-3: --grey-3;

  --brand: #e0bd2f;
  --brandsub: #cea813;

  --info: #00f;
  --warning: #ff0;
  --error: #f00;
  --success: #0f0;

  --font-size-10: 10px;
  --font-size-12: 12px;
  --font-size-14: 14px;
  --font-size-16: 16px;
  --font-size-18: 18px;
  --font-size-20: 20px;
  --font-size-24: 24px;
  --font-size-36: 36px;

  --font-weight-100: 100;
  --font-weight-200: 200;
  --font-weight-300: 300;
  --font-weight-400: 400;
  --font-weight-500: 500;
  --font-weight-600: 600;
  --font-weight-700: 700;
  --font-weight-800: 800;
  --font-weight-900: 900;
  --font-weight-bold: bold;
  --font-weight-bolder: bolder;
  --font-weight-lighter: lighter;

  --border-radius: 25px;
  --transition: var(--transition);

  padding-top: 98px;
}

.cur-point {
  cursor: pointer;
}

.cur-def {
  cursor: default;
}

.container {
  margin: 0 auto;
  padding: 0 15px;
  width: 100%;
  max-width: 1200px;
}

.flex {
  display: flex;
}

.flex-direction-column {
  flex-direction: column;
}

.flex-direction-row {
  flex-direction: row;
}

.flex-align-center {
  align-items: center;
}

.flex-justify-between {
  justify-content: space-between;
}

@each $width in $widths {
  $value: percentage($width/100);
  .w#{$width} {
    width: $value;
  }
}

@for $i from 0 through 16 {
  $value: 4 * $i;

  .mb-#{$i} {
    margin-bottom: #{$value}px;
  }

  .mt-#{$i} {
    margin-top: #{$value}px;
  }

  .my-#{$i} {
    margin-top: #{$value}px;
    margin-bottom: #{$value}px;
  }

  .ps-#{$i} {
    padding-left: #{$value}px;
  }

  .pe-#{$i} {
    padding-right: #{$value}px;
  }

  .px-#{$i} {
    padding-left: #{$value}px;
    padding-right: #{$value}px;
  }

  .py-#{$i} {
    padding-top: #{$value}px;
    padding-bottom: #{$value}px;
  }
}

.ps-60 {
  padding-left: 60px;
}

@media screen and (max-width: 960px) {
  body {
    padding-top: 98px;
  }

  .flex-sm {
    display: flex;
  }

  .flex-direction-column-sm {
    flex-direction: column;
  }

  .flex-direction-row-sm {
    flex-direction: row;
  }

  .flex-align-center-sm {
    align-items: center;
  }

  .flex-justify-between-sm {
    justify-content: space-between;
  }

  .block-sm {
    display: block;
  }

  .text-center-sm {
    text-align: center;
  }

  @each $width in $widths {
    $value: percentage($width/100);
    .w#{$width}-sm {
      width: $value;
    }
  }

  @for $i from 0 through 16 {
    $value: 4 * $i;

    .mb-sm-#{$i} {
      margin-bottom: #{$value}px;
    }

    .mt-sm-#{$i} {
      margin-top: #{$value}px;
    }

    .my-sm-#{$i} {
      margin-top: #{$value}px;
      margin-bottom: #{$value}px;
    }

    .ps-sm-#{$i} {
      padding-left: #{$value}px;
    }

    .pe-sm-#{$i} {
      padding-right: #{$value}px;
    }

    .px-sm-#{$i} {
      padding-left: #{$value}px;
      padding-right: #{$value}px;
    }

    .py-sm-#{$i} {
      padding-top: #{$value}px;
      padding-bottom: #{$value}px;
    }
  }
}
