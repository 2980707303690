.about {
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(75vh - 98px);
}

.contacts {
  display: flex;
  flex-direction: column;
}

.contactsItem {
  display: flex;
  margin-bottom: 16px;
  font-size: 14px;

  span {
    margin-right: 16px;
    font-weight: bold;
  }
}

@media screen and (max-width: 960px) {
  .about {
    flex-direction: column;
    height: auto;
  }
}
