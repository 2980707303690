.modalHeader {
  padding: 24px;

  button {
    position: absolute;
    right: 24px;
    top: 24px;
    padding: 0;
  }
}
