.modalBody {
  background-color: var(--white);
  box-shadow: 0 0 25px 10px rgba(0, 0, 0, 0.1);
  border-radius: var(--border-radius);

  padding: 0 24px;
  overflow: hidden;
  overflow-y: auto;
  max-height: calc(100vh - 240px);

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--grey-4);
  }
}
