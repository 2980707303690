.menu {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.item {
  display: inline-block;
  margin-left: 28px;
  position: relative;
  text-decoration: none;
  font-size: 14px;
  font-weight: bold;
  color: var(--grey-2);
  white-space: nowrap;
  transition: var(--transition);

  &:hover {
    color: var(--brand);
  }
}

.active {
  color: var(--brand);

  &::after {
    display: block;
    content: '';
    background-color: var(--brand);
    border-radius: 2px;
    width: 100%;
    height: 4px;
    position: absolute;
    top: 30px;
    left: 0;
  }

  &:hover {
    color: var(--brandsub);
  }
}

.btn {
  display: none;
  align-items: center;
  justify-content: center;
  background-color: var(--brand);
  width: 74px;
  height: 98px;
  position: absolute;
  top: 0;
  right: 0;

  svg {
    fill: var(--white);
  }
}

@media screen and (max-width: 960px) {
  .menu {
    flex-direction: column;
  }

  .btn {
    display: flex;
  }

  .items {
    display: none;
    flex-direction: column;
    background-color: var(--white);
    width: 100%;
    height: calc(100vh - 98px);
    position: fixed;
    top: 98px;
    left: 0;
    z-index: 9999;

    &.open {
      display: flex;
    }
  }

  .item {
    border-bottom: 1px solid var(--grey-6);
    margin: 0;
    padding: 20px;
    width: 100%;

    &::after {
      display: none;
    }
  }
}
