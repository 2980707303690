.modal {
  display: none;
  align-items: center;
  justify-content: center;

  position: fixed;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;
  z-index: 9999;
}

.background {
  background-color: var(--white);
  opacity: 0.75;

  position: fixed;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;
}

.cover {
  position: relative;
}

.open {
  display: flex;
}
