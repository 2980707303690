.videoPlayer {
  height: 100%;
}

.thumb {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--grey-6);
  border-radius: 25px;
  height: 100%;
  overflow: hidden;
  position: relative;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    opacity: 0;
    transition: var(--transition);
  }

  &:hover {
    img {
      opacity: 0.5;
    }
  }
}

.video {
  width: calc(100vw - 400px);
}

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 60px;
  height: 60px;
  position: absolute;
}
