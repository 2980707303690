.gallery {
  display: flex;
  flex-direction: column;
}

.container {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-bottom: 20px;
}

.img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.img1,
.img3,
.img2,
.img4,
.img5 {
  background: var(--grey-6);
  cursor: pointer;
  opacity: 0.9;
  width: 25%;
  height: 420px;
  overflow: hidden;
  transition: var(--transition);

  &:hover {
    opacity: 1;
  }
}

.img2,
.img4,
.img5 {
  width: 50%;
}

.img1,
.img2,
.img4 {
  border-top-right-radius: var(--border-radius);
  border-bottom-right-radius: var(--border-radius);
}

.img2,
.img3,
.img5 {
  border-top-left-radius: var(--border-radius);
  border-bottom-left-radius: var(--border-radius);
}

.imageWrapper {
  border-radius: var(--border-radius);
  box-shadow: 0 0 20px 0 rgb(0 0 0 / 15%);
  position: relative;
}

.imageBtnLeft,
.imageBtnRight {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--white);
  border-radius: var(--border-radius);
  cursor: pointer;
  width: 50px;
  height: 50px;
  position: absolute;
  top: calc(50% - 25px);
  transition: var(--transition);
}

.imageBtnLeft {
  left: -72px;
  svg {
    position: relative;
    left: -2px;
  }
}

.imageBtnRight {
  right: -72px;

  svg {
    position: relative;
    left: 2px;
    transform: rotate(180deg);
  }
}

.image {
  display: block;
  border-radius: var(--border-radius);
  width: 100%;
  height: auto;
  max-width: calc(100vw - 200px);
  max-height: calc(100vh - 200px);
}

@media screen and (max-width: 599px) {
  .img1,
  .img3,
  .img2,
  .img4,
  .img5 {
    height: 200px;
  }

  .image {
    max-width: calc(100vw - 15px);
    max-height: calc(100vh - 15px);
  }

  .imageBtnLeft {
    left: 15px;
  }

  .imageBtnRight {
    right: 15px;
  }
}
