.icon {
  & > svg {
    display: block;
  }
}

@for $i from 0 through 9 {
  $value: 45 * $i;
  .deg#{$i} {
    transform: rotate(#{$value}deg);
  }
}
