.wrapper {
  max-width: 960px;
}

.close {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--brand);
  border-radius: var(--border-radius);
  cursor: pointer;
  width: 50px;
  height: 50px;

  position: absolute;
  top: -20px;
  right: -20px;

  svg {
    fill: var(--white);
  }
}

.input {
  // background-color: var(--grey-6);
  border: 1px solid var(--grey-6);
  padding: 12px 16px;
  border-radius: var(--border-radius);
  width: 260px;
  font-size: 14px;

  &:first-of-type {
    margin-bottom: 12px;
  }
}

.button {
  margin-top: 32px;
}
